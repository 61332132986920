// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-blackbox-jsx": () => import("./../../../src/pages/portfolio/blackbox.jsx" /* webpackChunkName: "component---src-pages-portfolio-blackbox-jsx" */),
  "component---src-pages-portfolio-dlay-plugin-jsx": () => import("./../../../src/pages/portfolio/dlay_plugin.jsx" /* webpackChunkName: "component---src-pages-portfolio-dlay-plugin-jsx" */),
  "component---src-pages-portfolio-drum-padvisualizer-jsx": () => import("./../../../src/pages/portfolio/drum_padvisualizer.jsx" /* webpackChunkName: "component---src-pages-portfolio-drum-padvisualizer-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-portfolio-quarantine-quiz-jsx": () => import("./../../../src/pages/portfolio/quarantine_quiz.jsx" /* webpackChunkName: "component---src-pages-portfolio-quarantine-quiz-jsx" */),
  "component---src-pages-portfolio-radio-dramas-jsx": () => import("./../../../src/pages/portfolio/radio_dramas.jsx" /* webpackChunkName: "component---src-pages-portfolio-radio-dramas-jsx" */),
  "component---src-pages-portfolio-sound-design-jsx": () => import("./../../../src/pages/portfolio/sound_design.jsx" /* webpackChunkName: "component---src-pages-portfolio-sound-design-jsx" */),
  "component---src-pages-portfolio-sound-garden-jsx": () => import("./../../../src/pages/portfolio/sound_garden.jsx" /* webpackChunkName: "component---src-pages-portfolio-sound-garden-jsx" */)
}

